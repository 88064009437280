import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom'; 
//import './App.css';

//헤더 부분
import Header from './component/common/header'
//IQ200 설치, 원격요청 부분
import Iq200 from './component/iq200'
//메뉴얼 부분
import Manual from './component/manual'

const App = () => {
  return (
    <div className="App">
      <Router>
        <Header />
        <Iq200 />
        <Manual/>
      </Router>
    </div>
  );
}

export default App;
