import React from 'react';

const header = () => {
    return(
        <div>
            <div className="text-white font-bold bg-blue-500 text-lg text-center p-3 align-middle">
                한국식품안전관리인증원 민원응대 시스템
            </div>
        </div>
    )
}

export default header;