import React from 'react';
import { Link } from 'react-router-dom'

// test
const manual = () => {
    return (
        <div className="px-2">
            <div className="md:container md:mx-auto md:max-w-2xl rounded bg-white shadow-lg mt-4 mb-10">
                <div className="px-4 py-3 text-xl font-bold text-center text-black font-sans">
                    <h3>설치 메뉴얼</h3>
                </div>
                <hr/>
                <div className="grid grid-flow-col grid-cols-2 grid-rows-1 mx-auto flex items-center px-6 py-5">
                    <div className="md:flex-shrink-0">
                    <img className=" w-full h-full object-cover" src="/img/manual1.png" alt=" "/>
                    </div>
                    <div className="p-8">
                        <div className="uppercase tracking-wide text-lg text-indigo-500 font-semibold">1. 다운로드 및 실행</div>
                        <p className="mt-2 text-gray-500">
                            <Link to="/download/iq200_depart_setup_20211208_006.exe" target="_blank" download>
                                <span className="font-medium text-black hover:underline">프로그램 다운로드 </span>
                            </Link>
                            클릭 후 왼쪽
                        </p>
                        <p className="text-gray-500">
                            하단에 생성된 설치프로그램 클릭
                        </p>
                    </div>
                </div>
                <div className="grid grid-flow-col grid-cols-2 grid-rows-1 mx-auto flex items-center px-6 py-5">
                    <div className="md:flex-shrink-0">
                    <img className=" w-full h-full object-cover" src="/img/manual2.png" alt=" "/>
                    </div>
                    <div className="p-8">
                        <div className="uppercase tracking-wide text-lg text-indigo-500 font-semibold">2. 확인 클릭</div>
                        <p className="mt-2 text-gray-500">

                        </p>
                    </div>
                </div>
                <div className="grid grid-flow-col grid-cols-2 grid-rows-1 mx-auto flex items-center px-6 py-5">
                    <div className="md:flex-shrink-0">
                    <img className=" w-full h-full object-cover" src="/img/manual3.png" alt=" "/>
                    </div>
                    <div className="p-8">
                        <div className="uppercase tracking-wide text-lg text-indigo-500 font-semibold">3. 다음 클릭</div>
                        <p className="mt-2 text-gray-500">

                        </p>
                    </div>
                </div>
                <div className="grid grid-flow-col grid-cols-2 grid-rows-1 mx-auto flex items-center px-6 py-5">
                    <div className="md:flex-shrink-0">
                    <img className=" w-full h-full object-cover" src="/img/manual4.png" alt=" "/>
                    </div>
                    <div className="p-8">
                        <div className="uppercase tracking-wide text-lg text-indigo-500 font-semibold">4. 설치 클릭</div>
                        <p className="mt-2 text-gray-500">

                        </p>
                    </div>
                </div>
                <div className="grid grid-flow-col grid-cols-2 grid-rows-1 mx-auto flex items-center px-6 py-5">
                    <div className="md:flex-shrink-0">
                    <img className=" w-full h-full object-cover" src="/img/manual5.png" alt=" "/>
                    </div>
                    <div className="p-8">
                        <div className="uppercase tracking-wide text-lg text-indigo-500 font-semibold">5. 설치 진행</div>
                        <p className="mt-2 text-gray-500">
                        </p>
                    </div>
                </div>
                <div className="grid grid-flow-col grid-cols-2 grid-rows-1 mx-auto flex items-center px-6 py-5">
                    <div className="md:flex-shrink-0">
                    <img className=" w-full h-full object-cover" src="/img/manual6.png" alt=" "/>
                    </div>
                    <div className="p-8">
                        <div className="uppercase tracking-wide text-lg text-indigo-500 font-semibold">6. 설치 완료</div>
                        <p className="mt-2 text-gray-500">
                        </p>
                    </div>
                </div>
                <div className="grid grid-flow-col grid-cols-2 grid-rows-1 mx-auto flex items-center px-6 py-5">
                    <div className="md:flex-shrink-0">
                    <img className=" w-full h-full object-cover" src="/img/manual7.png" alt=" "/>
                    </div>
                    <div className="p-8">
                        <div className="uppercase tracking-wide text-lg text-indigo-500 font-semibold">7. 대표 전화번호 입력</div>
                        <p className="mt-2 text-gray-500">
                            대표번호 15991102 입력 후 확인 
                        </p>
                    </div>
                </div>
                <div className="grid grid-flow-col grid-cols-2 grid-rows-1 mx-auto flex items-center px-6 pt-5 pb-10">
                    <div className="md:flex-shrink-0">
                    <img className=" w-full h-full object-cover" src="/img/manual8.png" alt=" "/>
                    </div>
                    <div className="p-8">
                        <div className="uppercase tracking-wide text-lg text-indigo-500 font-semibold">8. 로그인 화면</div>
                        <p className="mt-2 text-gray-500">
                            아이디는 본인 전화 내선번호
                        </p>
                        <p className="text-gray-500">
                            비밀번호는 담당자에게 확인
                        </p>
                    </div>
                </div>
            </div>   
            {/* <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
                <div className="md:flex">
                    <div className="md:flex-shrink-0">
                    <img className="h-48 w-full object-cover md:h-full md:w-48" src="/img/store.jpg" alt="Man looking at item at a store"/>
                    </div>
                    <div className="p-8">
                        <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Case study</div>
                        <a href="#" className="block mt-1 text-lg leading-tight font-medium text-black hover:underline">Finding customers for your new business</a>
                        <p className="mt-2 text-gray-500">Getting a new business off the ground is a lot of hard work. Here are five ideas you can use to find your first customers.</p>
                    </div>
                </div>
            </div>    */}
        </div>
    );
}

export default manual;