import React from 'react';
import { Link } from 'react-router-dom'

const iq200 = () => {
    return (
        <div className="px-2">
            <div className="md:container md:mx-auto md:max-w-2xl rounded bg-white shadow-lg mt-16 px-2">
                <div className="px-4 py-3 text-xl font-bold text-black font-sans align-middle text-center">
                    <h3>설치프로그램 및 원격</h3>
                </div>
                <hr/>
                <div className="grid grid-flow-col grid-cols-2 grid-rows-1 mx-auto flex items-center px-16 py-5">
                    <Link to="/download/iq200_depart_setup_20211208_006.exe" target="_blank" download>
                        <div className="items-center bg-red-600 hover:shadow-inner cursor-pointer rounded-md shadow-md mr-2">
                            <div className="p-3 items-center">
                                <div className="text-center align-middle">
                                    <div className="text-lg font-bold text-white mb-2 font-sans">
                                        <p>프로그램 다운로드</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to="/download/bp00-1.exe" target="_blank" download>
                        <div className="items-center bg-blue-600 hover:shadow-inner cursor-pointer rounded-md shadow-md ml-2">
                            <div className="p-3 items-center">
                                <div className="text-center align-middle">
                                    <div className="text-lg font-bold text-white mb-2 font-sans">
                                        <p>설치 원격요청</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default iq200;